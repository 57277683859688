<template lang="pug">
div
  slot(name="activator" :toggle="toggle")
    v-btn(small color='primary' 
      @click="toggle" 
      :disabled="disabled"
      outlined
      style="pointer-events: all"
    )
      | {{ title }}
      v-tooltip(v-if="showLicensesTooltip" top max-width="400").tooltip
        template(v-slot:activator="{ on }")
          v-icon.tooltip__icon(small v-on="on") mdi-information
        span To add multiple requests, please filter the student list by the license type
  v-dialog(v-model="showing" scrollable max-width=800)
    v-card(data-test="c-exchange-body")
      v-card-title(data-test="c-exchange-title") {{ title }}
      v-card-text
        reservations(
          :formErrors="formErrors"
          ref="reservations"
          addText="add exam"
          :getExamLocation="getExamLocation"
          :getCourseLocation="getCourseLocation"
          :model="model"
          :avaliableSlots="false"
          :onHoldShowing="false"
          :licenseTypes="licenseTypes"
        )
          template(v-slot:form="form")
            slotsProvider(v-slot="slots" :key="showing")
              reservationSlotsProvider(
                :examDate="form.item.examDate"
                :examLocation="form.item.location"
                @change="slots.loadList"
                v-slot="slotsCheck"
              )
                list(
                  v-if="Object.keys(slots.model.list).length"
                  :model="slots.model"
                  :loading="slots.loading"
                  :pagination="slots.pagination"
                  @change:location="slotsCheck.checkDataToLoadList"
                  @change:examDate="slotsCheck.checkDataToLoadList"
                  v-show="!slots.loadingErr"
                )

        div.text-center.mt-3
        
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="toggle") Close
        v-btn(color="success" data-test="c-exchange-submit" text @click="submit" :loading="loading") Add exams
</template>

<script>
import { mapActions } from 'vuex'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from '@/util/form-errors'
import Model from '../core/candidates-reservation-model'
import { API_ATTRIBUTES_NESTED } from '../core/candidates-const'
import CandidateRequestsValidator from '../core/models/validation/CandidateRequestsValidator'
import CourseModel from '../../../models/courseModel'

export default {
  mixins: [showingMixin, errorsMixin],

  props: {
    exchange: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Add exam requests"
    },
    disabled: Boolean,
    activeItems: Array,
    defaultCourse: Object,
    showLicensesTooltip: Boolean,
    licenseTypes: Array,
  },

  data: () => ({
    loading: false,
    formErrors: new FormErrors(),
    model: new Model(),
  }),

  inject: ['svc'],

  methods: { 
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
      getCourseLocation: 'crmCourseCities/list'
    }),

    Svc() {
      return this.svc()
    },

    submit() {
      this.model.requests = this.$refs.reservations.requests
      this.formErrors.resetFields()
      let exchangeRequests = this.model._getRequestsApiDataBy(this.$refs.reservations.requests)
      new CandidateRequestsValidator(this.model.requests, this.formErrors, 'requests').validate()
      if (exchangeRequests.length) {
        let fieldsNotFilled = Object.values(exchangeRequests[0]).every(field => !field)

        if(fieldsNotFilled) {
          this.$notify({text: 'Please, fill fields before sent', type: 'error'})
          return
        }
      }
      if(!this.formErrors.isValid()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send()
    },

    async send() {
      try {
        this.loading = true
        let ids = this.activeItems.map(item => item.ID)
        let exchangeRequests = this.model._getRequestsApiDataBy(this.$refs.reservations.requests, this.exchange)
        let res = await this.svc().addRequests({ids: ids, exchangeRequests: exchangeRequests})
        this.$notify({text: 'Exchange list sended', type: 'success'})
        this.toggle()
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors, 
          apiAttributes: {
            exam_requests: 'requests'
          },
          nestedErrors: API_ATTRIBUTES_NESTED
        });
      } finally {
        this.loading = false
      }
    },

    prefill() {
      if (this.defaultCourse && this.defaultCourse.item) {
        let course = new CourseModel({
          id: this.defaultCourse.item.ID,
          date: this.defaultCourse.item._date,
          name: this.defaultCourse.item.name,
          city: this.defaultCourse.item.city
        })
        this.model.requests[0].course = course
        this.$refs.reservations && this.$refs.reservations.setRequests()
      }
    },

    reset() {
      this.model = new Model()
      this.$refs.reservations.resetRequests()
    }
  },

  watch: {
    showing(isShowing) {
      if (!isShowing) this.reset()
      else this.prefill()
    }
  },

  components: {
    exchangeItem: () => import('./CandidatesExchangeItem.vue'), 
    reservations: () => import('./item/CanditeReservations.vue'),
    list: () => import('@/app/admin/modules/slots/components/SlotsList.vue'),
    slotsProvider: () => import('@/app/admin/modules/slots/components/SlotsProvider.vue'),
    reservationSlotsProvider: () => import('./CandidatesReservationSlotsProvider.vue')
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  &__icon {
    margin-left: 4px;
  }
}
</style>
